import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  ExportButton,
  Filter,
  BooleanInput,
  ReferenceField,
  DateField,
  BooleanField,
  SelectInput,
  EditButton,
} from 'react-admin';

const CodeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Багцын ID" source="journeyId" alwaysOn />
    <TextInput label="Хэрэглэгчийн ID" source="userId" alwaysOn />
    <SelectInput
      label="Certificate авсан эсэх"
      source="isCertificateEarned"
      emptyText="Бүгд"
      choices={[
        { id: 'true', name: 'Тийм' },
        { id: 'false', name: 'Үгүй' },
      ]}
      alwaysOn
    />
  </Filter>
);

const LingoUserJourneysActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
    </Toolbar>
  );
};

export const LingoUserJourneysList = props => (
  <List {...props} filters={<CodeFilter/>} actions={<LingoUserJourneysActions props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField label="Хэрэглэчгч" source="userId" reference="users">
        <TextField label="Нэр" source="firstName" />
      </ReferenceField>
      <TextField label="Тестийн оноо" source="testScore" />
      <ReferenceField label="Багцын ID" source="journeyId" reference="lingo/journeys">
        <TextField label="Нэр" source="title" />
      </ReferenceField>
      <BooleanField label="Certificate авсан эсэх" source="isCertificateEarned" />
      <DateField label="Огноо" source="updatedAt" />
      <EditButton />
    </Datagrid>
  </List>
);

export const LingoUserJourneysEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField label="ID" source="id" />
        <ReferenceField label="Хэрэглэчгч" source="userId" reference="users">
          <TextField label="Нэр" source="firstName" />
        </ReferenceField>
        <TextField label="Тестийн оноо" source="testScore" />
        <ReferenceField label="Багцын ID" source="journeyId" reference="lingo/journeys">
          <TextField label="Нэр" source="title" />
        </ReferenceField>
        <BooleanInput label="Certificate авсан эсэх" source="isCertificateEarned"/>
      </SimpleForm>
    </Edit>
  )
};
