import React, { useState } from 'react';
import { useNotify, useDataProvider, Button, useRefresh } from 'react-admin';
import { UNITEL_CARRIER_BILLING_CHOICES } from '../constants';

const CarrierBillingSubscriptionChange = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [status, setStatus] = useState(UNITEL_CARRIER_BILLING_CHOICES[0].id)
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    dataProvider.patch(`carrierBillingSubscriptions/${record.id}`, { data: { status } })
      .catch((e) => {
        console.log(e)
        notify('Error: code not activated', 'warning')
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };
  return (
    <>
      <select value={status.id} onChange={(ev) => setStatus(ev.target.value)}>
        {UNITEL_CARRIER_BILLING_CHOICES.map((plan) => <option value={plan.id}>{plan.name}</option>)}
      </select>
      <Button onClick={handleClick} label="Төлөв өөрчлөх" disabled={loading} />
    </>
  );
};

export default CarrierBillingSubscriptionChange;
