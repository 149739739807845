import React from 'react';
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    TextInput,
    EditButton,
    SelectArrayInput,
    FunctionField,
    SelectInput,
    NumberInput, useQuery, required,
} from 'react-admin';

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}

const QuizLanguageInput = () => {
    const { data: languages } = useQuery({
        type: 'getList',
        resource: 'languages',
        payload: {
            pagination: { page: 1, perPage: 500 },
            sort: { field: 'id', order: 'DESC' },
            filter: {},
        },
    });
    return (
        <SelectArrayInput
            source="languageTags"
            optionText="name"
            optionValue="name"
            choices={languages}
            style={{ width: 255 }}
            validate={[required()]}
        />
    )
};

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const LTQuizBundleList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Title" source="title" />
      <FunctionField label="Үнэ" render={record => `${formatNumber(record.price)} MNT`} />
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <EditButton />
    </Datagrid>
  </List>
);

const QuizBundleTitle = ({ record }) => {
  const { id } = record;
  return <span>Quiz Bundle {record ? `"${id}"` : ''}</span>;
};

export const LTQuizBundleEdit = props => (
  <Edit title={<QuizBundleTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput fullWidth label="Title" source="title" />
      <TextInput fullWidth label="Нэмэлт тайлбар" source="additionalDescription" />
      <NumberInput label="Үнэ" source="price" />
      <QuizLanguageInput/>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const LTQuizBundleCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput fullWidth label="Title" source="title" />
      <TextInput fullWidth label="Нэмэлт тайлбар" source="additionalDescription" />
      <NumberInput label="Үнэ" source="price" />
      <QuizLanguageInput/>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Create>
);
