import React from 'react';
import { MENTOR_TOPICS } from '../constants/AskingMentor';
import Toolbar from '@material-ui/core/Toolbar';
import {CreateButton, ReferenceArrayInput, SelectArrayInput} from 'react-admin';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  ReferenceField,
  TextInput,
  EditButton,
  ReferenceInput,
  SelectInput,
  required,
  minLength,
  SimpleFormIterator,
  ArrayInput,
  FileField,
  FileInput,
  AutocompleteInput,
  FunctionField,
  Filter,
  NumberInput,
  BooleanInput,
} from 'react-admin';
import { COUNTRY_NAMES } from '../constants';
import VerifiedMentorToggleBtn from './VerifiedMentorToggleBtn';

const validateRequired = [required(), minLength(3)];

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}

const TextFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="userId" source="userId" alwaysOn/>
    <TextInput label="Сургууль" source="university" alwaysOn/>
  </Filter>
);

const UserAction = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const MentorList = props => (
  <List {...props} filters={<TextFilter/>} actions={<UserAction props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField source="userId" reference="users">
        <TextField label="Нэр" source="firstName" />
      </ReferenceField>
      <TextField label="Сургууль" source="university" />
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <VerifiedMentorToggleBtn label="Verified?" />
      <EditButton />
    </Datagrid>
  </List>
);

const MentorTitle = ({ record }) => {
  const { id } = record;
  return <span>Mentor {record ? `"${id}"` : ''}</span>;
};

export const MentorEdit = props => (
  <Edit title={<MentorTitle />} {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={false} />
      <TextInput disabled source="id" />
      <ReferenceInput source="userId" reference="users">
        <AutocompleteInput
          optionText={item => (item.email ? item.email : '')}
          shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
          options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <TextInput multiline fullWidth rows={8} label="Намтар" source="bio" />
      <TextInput
        label="Сургууль"
        source="university"
        validate={validateRequired}
      />
      <SelectInput
        label="Улс"
        source="country"
        choices={COUNTRY_NAMES}
      />
      <ReferenceInput label="Хэл (tag)" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд (tag)" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput
        label="Боловсролын зэрэг"
        source="degree"
        validate={validateRequired}
      />
      <ReferenceArrayInput source="tagging.majorFieldIds" label="Чиглэл (tag)" validate={[required()]} reference="majorFields">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput
        label="Мэргэжил"
        source="profession"
        validate={validateRequired}
      />
      <TextInput label="Сошэйл медиа" source="social" />
      <ArrayInput source="mentoringTopics">
        <SimpleFormIterator>
          <SelectInput choices={MENTOR_TOPICS} />
        </SimpleFormIterator>
      </ArrayInput>
      <FileInput source="file" label="Танилцуулга видео" accept="video/mp4">
        <FileField source="video" title="video" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const MentorCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput label="Notification явуулах уу?" source="shouldSendNotification" initialValue={true} />
      <ReferenceInput source="userId" reference="users">
        <AutocompleteInput
          optionText={item => (item.email ? item.email : '')}
          shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
          options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <TextInput multiline fullWidth rows={8} label="Намтар" source="bio" />
      <TextInput
        label="Сургууль"
        source="university"
        validate={validateRequired}
      />
      <SelectInput
        label="Улс"
        source="country"
        choices={COUNTRY_NAMES}
      />
      <ReferenceInput label="Хэл (tag)" source="tagging.languageId" reference="languages" validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="tagging.countryIds" label="Харгалзах улсууд (tag)" validate={[required()]} reference="countries">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput
        label="Боловсролын зэрэг"
        source="degree"
        validate={validateRequired}
      />
      <TextInput
        label="Мэргэжил"
        source="profession"
        validate={validateRequired}
      />
      <TextInput label="Сошэйл медиа" source="social" />
      <ArrayInput source="mentoringTopics">
        <SimpleFormIterator>
          <SelectInput choices={MENTOR_TOPICS} />
        </SimpleFormIterator>
      </ArrayInput>
      <FileInput source="file" label="Танилцуулга видео" accept="video/mp4">
        <FileField source="video" title="video" />
      </FileInput>
      <SelectInput
        label="статус"
        source="status"
        choices={[
          { id: 'draft', name: 'драфтласан' },
          { id: 'published', name: 'нийтлэсэн' },
        ]}
      />
    </SimpleForm>
  </Create>
);
