import React from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput,
  EditButton
} from 'react-admin';


export const FreeTrialPlanList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField source="id" />
      <TextField label="тайлбар" source="description" />
      <TextField label="хугацаа тоогоор" source="dayOfTrial" />
      <BooleanField label="Идэвхитэй" source="active" />
      <EditButton />
    </Datagrid>
  </List>
);

export const FreeTrialPlanEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="тайлбар" source="description" />
      <TextInput label="хугацаа тоогоор" source="dayOfTrial" />
      <BooleanInput label="Идэвхитэй" source="active" />
    </SimpleForm>
  </Edit>
);


export const FreeTrialPlanCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="тайлбар" source="description" />
      <TextInput label="хугацаа тоогоор" source="dayOfTrial" />
      <BooleanInput label="Идэвхитэй" source="active" />
    </SimpleForm>
  </Create>
);
