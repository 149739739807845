import React, { useState } from 'react';
import { useNotify, Button } from 'react-admin';
import dataProvider from '../utils/dataProvider';

const VideoDownloadButton = ({ id }) => {
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    await dataProvider.post(`coursesSections/${id}/download`, {})
      .then((res) => {
        // redirect to new tab
        window.open(res?.downloadLink, '_blank');
        notify('Амжилттай', 'info')
      })
      .catch((e) => {
        console.log(e)
        notify('Error: not download video', 'warning')
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Button onClick={handleClick} label="Видео татах" disabled={loading} style={{ marginTop: '10px' }} />
    </>
  );
};

export default VideoDownloadButton;
