import React from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  EditButton,
  DateField,
  DateTimeInput,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
} from 'react-admin';

export const TimeslotList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField label="Id" source="id" />
      <DateField label="Start time" source="starting" showTime/>
      <DateField label="End time" source="ending" showTime/>
      <ReferenceField label="Mentor email" source="mentor.user.id" reference="users">
        <TextField label="Mentor email" source="email" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const TimeslotEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <DateTimeInput label="Start time" helperText="Улаанбаатарын (+08:00) цагаар" source="starting"/>
      <DateTimeInput label="End time" helperText="Улаанбаатарын (+08:00) цагаар" source="ending"/>
      <TextField label="Mentor email" source="mentor.user.email" />
    </SimpleForm>
  </Edit>
);

export const TimeslotCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Mentor Email" source="userId" reference="users">
        <AutocompleteInput
          optionText={item => (item.email ? item.email : '')}
          shouldRenderSuggestions={(val) => { return val.trim().length > 1 }}
          options={{ suggestionsContainerProps: { disablePortal: true } }}
        />
      </ReferenceInput>
      <DateTimeInput label="Start time" helperText="Улаанбаатарын (+08:00) цагаар" source="starting"/>
      <DateTimeInput label="End time" helperText="Улаанбаатарын (+08:00) цагаар" source="ending"/>
    </SimpleForm>
  </Create>
)