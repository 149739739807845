export const FEATURES = [
  { id: 'challenge', name: 'challenge' },
  { id: 'adaptiveTestV2', name: 'adaptiveTestV2' },
  { id: 'freemium-access', name: 'freemium-access' },
  { id: 'mentorQnA', name: 'mentorQnA' },
  { id: 'payment', name: 'payment' },
  { id: 'mobicom', name: 'mobicom' },
  { id: 'skills', name: 'skills' },
  { id: 'no_more_10_min', name: 'no_more_10_min' },
  { id: 'new_explore_screen', name: 'new_explore_screen' },
  { id: 'streaming', name: 'streaming' },
  { id: 'change_language', name: 'change_language' },
  { id: 'commenting', name: 'commenting' },
  { id: 'guided_journey_program', name: 'guided_journey_program' },
  { id: 'class', name: 'class' },
  { id: 'freeTrial', name: 'freeTrial' },
  { id: 'unitel_carrier_billing', name: 'unitel_carrier_billing' },
];
