import React from 'react';
import {
  List,
  Create,
  Edit,
  SimpleForm,
  Datagrid,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput,
  EditButton
} from 'react-admin';


export const LingoAppReleaseList = props => (
  <List {...props}>
    <Datagrid optimized>
      <TextField source="id" />
      <TextField label="хувилбар" source="version" />
      <BooleanField label="enabled" source="enabled" />
      <BooleanField label="requiredUpdateIOS" source="requiredUpdateIOS" />
      <BooleanField label="requiredUpdateAndroid" source="requiredUpdateAndroid" />
      <EditButton />
    </Datagrid>
  </List>
);

export const LingoAppReleaseEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="хувилбар" source="version" />
      <BooleanInput label="enabled" source="enabled" />
      <BooleanInput label="requiredUpdateIOS" source="requiredUpdateIOS" />
      <BooleanInput label="requiredUpdateAndroid" source="requiredUpdateAndroid" />
    </SimpleForm>
  </Edit>
);


export const LingoAppReleaseCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="хувилбар" source="version" />
      <BooleanInput label="enabled" source="enabled" />
      <BooleanInput label="requiredUpdateIOS" source="requiredUpdateIOS" />
      <BooleanInput label="requiredUpdateAndroid" source="requiredUpdateAndroid" />
    </SimpleForm>
  </Create>
);
